import * as focusTrap from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';

class FlyoutForm {
	constructor() {
		this.formTriggers = document.querySelectorAll('[data-flyout-form]');
		this.formClose = document.querySelectorAll('.flyout-form__close');
		this.flyoutOverlay = document.querySelector('.flyout-overlay');

		this.formTriggers.forEach((trigger) => {
			trigger.addEventListener('click', this.handleTriggerClick.bind(this));
		});

		this.formClose.forEach((close) => {
			close.addEventListener('click', this.closeFlyout.bind(this));
		});

		this.flyoutOverlay.addEventListener('click', this.closeFlyout.bind(this));

		document.body.addEventListener('keyup', this.handleKeyUp.bind(this));
	}

	handleKeyUp(e) {
		if (27 === e.keyCode) {
			this.closeFlyout();
		}
	}

	handleTriggerClick(e) {
		const { currentTarget } = e;
		const flyoutId = currentTarget.getAttribute('data-flyout-form');

		const flyoutForm = document.getElementById(flyoutId);
		this.activateFlyout(flyoutForm);
	}

	activateFlyout(form) {
		if (null === form) {
			return;
		}

		form.setAttribute('aria-hidden', 'false');
		window.focusTrap = focusTrap.createFocusTrap(form, {
			allowOutsideClick: true,
		});
		window.focusTrap.activate();
		document.body.classList.add('flyout-open');
		disableBodyScroll(form);
		form.querySelector('.flyout-form__close').focus();
	}

	closeFlyout() {
		const form = document.querySelector('.flyout-form[aria-hidden="false"]');
		if (null === form) {
			return;
		}

		document.body.classList.remove('flyout-open');
		window.focusTrap.deactivate();
		enableBodyScroll(form);

		if (null !== form) {
			form.setAttribute('aria-hidden', 'true');
		}

		const closeEvent = new Event('closeFlyoutForm');
		document.dispatchEvent(closeEvent);
	}
}

new FlyoutForm();
